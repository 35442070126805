
.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.react-datepicker-wrapper{
  width: 100% ;
  /* background-color: black !important; */
}
.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.Toastify__toast-container--top-right{
  /* position: relative !important; */
  /* top: 100% !important; */
  margin-top: -355px;
/* right: 10% !important; */
/* bottom: 90% !important; */
  /* left: 74%!important; */
}
